import { accountService } from "services/account";
import useSWR from "swr";

export const useFetchSettings = () => {
    const { data: accountSettings, error } = useSWR(
        "/api/home/settings",
        accountService.getSettings
    );

    const isLoading = !error && !accountSettings;

    return { accountSettings, isLoading, error };
};
