import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ShowLoadingContext = createContext();

export const ShowLoadingContextProvider = ({ children }) => {
    const [showLoading, setShowLoading] = useState(null);

    return (
        <ShowLoadingContext.Provider value={{ showLoading, setShowLoading }}>
            {children}
        </ShowLoadingContext.Provider>
    );
};

ShowLoadingContextProvider.propTypes = {
    children: PropTypes.any,
};
