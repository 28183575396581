import PropTypes from "prop-types";
import { useRef } from "react";

import {
  Orientation,
  ToggleGroup,
} from "components/Base/ToggleGroup/ToggleGroup";
import { toToggleGroupValues } from "../shared/toToggleGroupValues";

export function Checkboxes({
  ariaDescribedBy,
  choices,
  readOnly,
  oid,
  onChange,
  layout,
  valid,
  value,
}) {
  const options = useRef(toToggleGroupValues(choices));

  return (
    <ToggleGroup
      ariaDescribedBy={ariaDescribedBy}
      choices={options.current}
      disabled={readOnly}
      id={oid}
      onChange={onChange}
      orientation={layout.orientation ?? Orientation.Horizontal}
      role="group"
      valid={valid}
      value={Array.isArray(value) ? value : value === null ? [] : [value]}
      multiple
    />
  );
}

Checkboxes.propTypes = {
  ariaDescribedBy: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      choiceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      choiceText: PropTypes.string.isRequired,
    })
  ),
  disabled: PropTypes.bool,
  layout: PropTypes.shape({
    orientation: PropTypes.oneOf([Orientation.Vertical, Orientation.Horizontal])
      .isRequired,
  }),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};
