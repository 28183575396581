import "./DashboardTile.css";

import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink } from "react-router-dom";

export function DashboardTile({
  children,
  className,
  controls,
  disabled,
  page,
  hasError,
  testId,
  wrapperComponent,
  isExternalUrl
}) {
  // Use custom wrapper component if provided. If not use RouteLink if page is provided. Default to section element. 
  const WrapperComponent = wrapperComponent ?? (page ? ({ children, ...props }) => (
    isExternalUrl 
    ? <a target="_blank" rel="noreferrer" href={page} {...props}>{children}</a>
    : <RouterLink to={page} {...props}>{children}</RouterLink>
  ) : "section");

  return (
    <WrapperComponent
      data-testid={testId}
      className={classNames({
        "dashboard-tile": true,
        "dashboard-tile--disabled": disabled,
        "dashboard-tile--error": hasError,
        [className]: !!className,
      })}
      tabIndex={disabled ? -1 : 0}
    >
      <div className="dashboard-tile__content">{children}</div>
      {controls && <div className="dashboard-tile__controls">{controls()}</div>}
    </WrapperComponent>
  );
}

DashboardTile.defaultProps = {
  className: "",
  disabled: false,
  hasError: false,
  isExternalUrl: false
};

DashboardTile.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  controls: PropTypes.func,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  page: PropTypes.string,
  testId: PropTypes.string,
  wrapperComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isExternalUrl: PropTypes.bool,
};
