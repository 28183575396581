/* eslint-disable no-useless-escape, no-control-regex */

export const isNumericFormat = /^(\d+)((\.\d+)|)$/;

// String since used both as HTML element pattern and match regex
export const pinCodeFormat = "[0-9]{4,}";

export const phoneNumberFormat = /^((\+)|(00)|())[0-9]{3,14}$/;

export const emailFormat =
    /^((([a-zA-Z0-9]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])+(\.([a-zA-Z0-9]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])|(([a-zA-Z0-9]|\d|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])([a-zA-Z0-9]|\d|-|\.|_|~|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])*([a-zA-Z0-9]|\d|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])))\.)+(([a-z]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])|(([a-zA-Z0-9]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])([a-zA-Z0-9]|\d|-|\.|_|~|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])*([a-zA-Z0-9]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])))\.?$/;

export function stringFormat(data, ...args) {
    for (var index in args) {
        data = data.replace("{" + index + "}", args[index]);
    }
    return data;
}