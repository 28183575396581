import { extendArrayWithContains } from "./arrayContains";

const getDefaultItemValue = (itemType) => {
    if (itemType === "Checkboxes") {
        return [];
    }

    return null;
};

export const updateVisibility = (form, visibilityFunctions, resetField = null) => {
    for (const itemGroup of form.itemGroups) {
        for (const item of itemGroup.items) {
            item.isVisible = setIsVisible(item, visibilityFunctions, form);

            if (!item.isVisible) {
                form.formData[item.oid] = getDefaultItemValue(item.itemType);
                if (resetField) {
                    resetField(item.oid);
                }
            }
        }

        itemGroup.isVisible = setIsVisible(
            itemGroup,
            visibilityFunctions,
            form
        );
    }
};

export const createVisibilityFunctions = (form) => {
    const visibilityFunctions = {};

    for (const itemGroup of form.itemGroups) {
        visibilityFunctions[itemGroup.oid] = getVisibilityFunction(itemGroup);
        for (const item of itemGroup.items) {
            visibilityFunctions[item.oid] = getVisibilityFunction(item);
        }
    }

    return visibilityFunctions;
};

const setIsVisible = (item, visibilityFunctions, form) =>
    visibilityFunctions[item.oid](form);

const getVisibilityFunction = (item) => {
    if (
        item.visibility.advancedExpression &&
        item.visibility.advancedExpression !== "true"
    ) {
        return createVisibilityFunction(item.visibility.advancedExpression);
    }

    if (item.items) {
        //if all items are hidden , group is also hidden
        return () => item.items.some((i) => i.isVisible);
    }

    return () => true;
};

// Code taken from legacy Viedoc Me
const createVisibilityFunction = (functionJs) => {
    extendArrayWithContains(); // extend array prototype with contains method since it can be used in visibility functions

    function clone(obj) {
        if (obj === null || typeof obj !== "object" || "isActiveClone" in obj)
            return obj; //primitive types or already cloned in cyclic reference

        var clonedObject;
        if (obj instanceof Date) {
            clonedObject = new Date(obj);
        } else if (obj.constructor === Array) {
            clonedObject = obj.slice(0);
        } else {
            clonedObject = obj.constructor();

            for (var propName in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, propName)) {
                    obj["isActiveClone"] = null;
                    clonedObject[propName] = clone(obj[propName]);
                    delete obj["isActiveClone"];
                }
            }
        }
        return clonedObject;
    }

    function createSandbox(code, locals) {
        //code = '"use strict";' + code; not sure if we can use this

        var params = []; // the names of local variables
        var args = []; // the local variables
        for (var propName in locals) {
            if (Object.prototype.hasOwnProperty.call(locals, propName)) {
                args.push(locals[propName]);
                params.push(propName);
            }
        }

        var that = Object.create(null); // create our own this object for the user code

        var context = Array.prototype.concat.call(that, params, code); // create the parameter list for the sandbox

        var sandbox = new (Function.prototype.bind.apply(Function, context))(); // create the sandbox function

        context = Array.prototype.concat.call(that, args); // create the argument list for the sandbox

        return Function.prototype.bind.apply(sandbox, context); // bind the local variables to the sandbox
    }

    //returns an array of objects for the matching indexes
    //if there was no match, an empty array is returned
    function getIndexesOfKeyWord(text, keyword, index, indexes) {
        indexes = indexes || [];
        //index of the first char in the keyword
        var indexOfKeyWord = text.indexOf(keyword, index);

        if (indexOfKeyWord > -1) {
            //index of the last char in the keyword
            var lastIndexOfKeyWord = indexOfKeyWord + keyword.length - 1;

            indexes.push({ first: indexOfKeyWord, last: lastIndexOfKeyWord });

            //invokes self to find more indexes
            getIndexesOfKeyWord(text, keyword, lastIndexOfKeyWord + 1, indexes);
        }

        return indexes;
    }

    function containsKeyWord(text, keyword) {
        //find indexes of the keyword in the text
        //if no indexes was found, this array will be empty
        var indexOfKeyWord = getIndexesOfKeyWord(text, keyword, 0);

        for (var i = 0; i < indexOfKeyWord.length; i++) {
            var charBeforeKeyWord = text[indexOfKeyWord[i].first - 1];
            var charAfterKeyWord = text[indexOfKeyWord[i].last + 1];

            //the char before and after the keyword is null/undefined which means that the keyword is at the start or end position of the string,
            //hence not matching any word (alphanumeric and underscore)

            //if the keyword is not at the start or end position of the string, a regex is run to ensure that it doesn't match any word (alphanumeric and underscore)

            //this indicates that the keyword is an object property on the form model
            if (
                (charBeforeKeyWord == null || charBeforeKeyWord.match(/\W/)) &&
                (charAfterKeyWord == null || charAfterKeyWord.match(/\W/))
            ) {
                return true;
            }
        }

        return false;
    }

    function extend(toObj, fromObj, searchText) {
        for (var propName in fromObj) {
            if (Object.prototype.hasOwnProperty.call(fromObj, propName)) {
                if (containsKeyWord(searchText, propName)) {
                    toObj[propName] = clone(fromObj[propName]);
                }
            }
        }
    }

    return function (form) {
        var locals = {}; //create an object with variables used in functionJs

        //extend(locals, document._globalMask, functionJs); this creates issues, so lets use with(document._globalMask)

        extend(locals, form.dependencies, functionJs);
        extend(locals, form.identifierModel, functionJs);
        extend(locals, form.formData, functionJs);

        try {
            var sandbox = createSandbox(
                //"with(document._globalMask){ document = undefined; window=undefined;" +
                "return " + functionJs,
                //+"}"
                locals
            );
            var value = sandbox();
            return value;
        } catch (ex) {
            if (console && console.error) {
                console.error("ERROR in expression \n" + functionJs + ex);
            }
            if (this.DesignValidationErrors)
                this.DesignValidationErrors.push(ex);
        }
    };
};
