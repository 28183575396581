import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFetchNextEvent } from "hooks/api/useFetchNextEvent";
import { BoxSection } from "components/Base/Box/BoxSection/BoxSection";
import { AnimatedCheck } from "components/Base/AnimatedCheck/AnimatedCheck";
import { NextEventSection } from "./NextEventSection";
import { LoadingContext, actionTypes } from "context/LoadingContext";
import { ShowLoadingContext } from "context/ShowLoadingContext";

import "./FormThankYouPage.css";
import { StaggeredAnimation } from "components/Base/Box/StaggeredAnimation/StaggeredAnimation";

export function FormThankYouPage({ onAnimationEnd }) {
  const { t } = useTranslation();

  const { showLoading } = useContext(ShowLoadingContext);
  const { dispatch } = useContext(LoadingContext);
  const [animtedCheckCompleted, setAnimtedCheckCompleted] = useState(false);

  const { event, isLoading } = useFetchNextEvent();

  useEffect(() => {
    const LOADING_KEY = "next-event";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  return (
    <>
      {!animtedCheckCompleted ? (
        <BoxSection>
          <div className="thank-you-page__main-content">
            {!isLoading && !showLoading && (
              <AnimatedCheck
                className="thank-you-page__check-icon"
                onAnimationEnd={() => {
                  setAnimtedCheckCompleted(true);
                }}
              />
            )}
          </div>
        </BoxSection>
      ) : (
        <StaggeredAnimation
          animationKey="thank-you-page"
          onAnimationEnd={onAnimationEnd}
        >
          <BoxSection>
            <div className="thank-you-page__main-content">
              <h3 className="thank-you-page__title">
                {t("form.thank_you.thank_you")}
              </h3>
              <p className="thank-you-page__paragraph">
                {t("form.thank_you.submit_success")}
              </p>
            </div>
          </BoxSection>
          {event && <NextEventSection event={event} />}
        </StaggeredAnimation>
      )}
    </>
  );
}

FormThankYouPage.propTypes = {
  onAnimationEnd: PropTypes.func.isRequired,
};
