import { useEffect, useState } from "react";
import { authenticationService } from "services/authentication";
import { useFetchStudyInfo } from "hooks/api/useFetchStudyInfo";
import { history } from "router/history";
import { i18n } from "localization/i18n";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "components/Base/Button/Button";
import "./Welcome.css";

export function Welcome() {
  const [profile, setProfile] = useState({});
  const { studyInfo, isLoading, error } = useFetchStudyInfo();
  const getInfo = (key) => !isLoading && !error && studyInfo[key];

  useEffect(() => {
    async function getUser() {
      const user = await authenticationService.getUser();
      if(user){
        setProfile(user.profile);
        i18n.changeLanguage(user.profile.lang);
      }
    }

    getUser()
  }, [])

  const navigateToHomePage = () =>{ 
    history.push('/');
  }

  const { t } = useTranslation();

  const buildNavigateLink = () => {
    return `${window.location.origin}?login_hint=${profile.name}`;
  }

  const username = profile.name;

  return (
    <section className="welcome-page">
            <div>
                
                <h1 className="login__animated-title">
                <Trans i18nKey="welcome_page.welcome_user" value={{username}}>
                  Welcome, user <span className="login__user-name">{{username}}</span>
                </Trans>
                 </h1>
                <p>{t("welcome_page.username_is_unique_identity_for_the_study", {["studyName"]: getInfo("studyName")})}
                        <br/>
                        {t("welcome_page.instruction_following_url")} 
                        <br/>
                        <a href={buildNavigateLink()}>{buildNavigateLink()}</a> 
                </p>
                <div className="pt-20">
                <Button
                    size="large"
                    color="purple-dark"
                    className="button-continue"
                    label={t("welcome_page.continue_button")}
                    onClick={navigateToHomePage}
                  />
                </div>
            </div>
    </section>
  );
}
