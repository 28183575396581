import { studyService } from "services/study";
import useSWR from 'swr';

export const useFetchStudyInfo = () => {
  const { data: studyInfo, error } = useSWR('/api/home/studyinfo', studyService.getStudyInfo);

  const isLoading = !error && !studyInfo;

  return { studyInfo, isLoading, error };
};
