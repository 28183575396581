import PropTypes from "prop-types";
import classNames from "classnames";

import "./HtmlText.css";

/**
 * Render HTML string as HTML
 * @returns
 */
export function HtmlText({
  className,
  element: HtmlTag,
  htmlString: __html,
  htmlFor,
}) {
  return (
    <HtmlTag
      className={classNames({
        "html-text": true,
        [className]: !!className,
      })}
      dangerouslySetInnerHTML={{ __html }}
      htmlFor={htmlFor}
    ></HtmlTag>
  );
}

HtmlText.propTypes = {
  className: PropTypes.string,
  element: PropTypes.string.isRequired,
  htmlString: PropTypes.string,
  htmlFor: PropTypes.string,
};

HtmlText.defaultProps = {
  htmlString: "",
};
