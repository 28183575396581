import PropTypes from "prop-types";
import classNames from "classnames";
import "./DateIcon.css";

export function DateIcon({ active, dayOfMonth, size }) {
  return (
    <div
      className={classNames({
        "date-icon": true,
        "date-icon--inactive": !active,
        "date-icon--small": size === "small",
      })}
    >
      <div className="date-icon__header"></div>
      <div className="date-icon__date">{dayOfMonth}</div>
    </div>
  );
}

DateIcon.defaultProps = {
  active: true,
  size: "medium",
};

DateIcon.propTypes = {
  active: PropTypes.bool,
  dayOfMonth: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium"]),
};
