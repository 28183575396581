import { http } from "./http";

const baseUrl = "/api/user/events";

const getEvents = () => http.get(baseUrl);
const getNextEvent = async () => http.get(`${baseUrl}/next`);
const getUnscheduledEvents = () => http.get(`${baseUrl}/unscheduled`);

export const userEventsService = {
    getEvents,
    getUnscheduledEvents,
    getNextEvent,
};
