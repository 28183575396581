import React, {
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import SignaturePad from "signature_pad";
import body from "content/images/body.png";
import sign from "content/images/sign.png";

import "./DrawingPad.css";

const backgroundImages = {
  sign,
  body,
};

export const DrawingPad = forwardRef(
  ({ backgroundImageName, onChange, value }, ref) => {
    const canvas = useRef(null);
    const signaturePad = useRef(null);
    const backgroundImage = useRef(null);
    const defaultBackgroundRatio = 0.5;
    const currentValue = useRef(value);
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    useImperativeHandle(ref, () => ({
      clear() {
        updateValue(null, true);
        redrawImage();
      },
    }));

    const getBackgroundRatio = () => {
      return backgroundImage.current
        ? backgroundImage.current.height / backgroundImage.current.width
        : defaultBackgroundRatio;
    };

    const updateValue = useCallback(
      (newValue, isReset) => {
        currentValue.current = newValue;
        onChange(newValue, undefined, isReset);
      },
      [onChange]
    );

    const redrawImage = useCallback(() => {
      const canvasEl = canvas.current;
      const image = backgroundImage.current;

      signaturePad.current.clear();
      if (image) {
        canvasEl
          .getContext("2d")
          .drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvasEl.width / ratio,
            canvasEl.height / ratio
          );
      }
      signaturePad.current.fromDataURL(currentValue.current);
    }, [ratio]);

    const resizeCanvas = useCallback(() => {
      const canvasEl = canvas.current;
      const backgroundRatio = getBackgroundRatio();

      canvasEl.width = canvasEl.offsetWidth * ratio;
      canvasEl.height = canvasEl.offsetWidth * ratio * backgroundRatio;
      canvasEl.getContext("2d").scale(ratio, ratio);

      redrawImage();
    }, [ratio, redrawImage]);

    // Initialize signature pad
    useEffect(() => {
      const options = {
        backgroundColor: "#FCFCFC",
        minWidth: 1.5,
        maxWidth: 3,
      };

      signaturePad.current = new SignaturePad(canvas.current, options);

      return () => {
        signaturePad.current.off();
      };
    }, []);

    // Add signature pad event handlers
    useEffect(() => {
      if (signaturePad.current) {
        signaturePad.current.onEnd = () => {
          updateValue(signaturePad.current.toDataURL());
          window.addEventListener("resize", resizeCanvas);
        };

        signaturePad.current.onBegin = () => {
          window.removeEventListener("resize", resizeCanvas);
        };
      }
    }, [updateValue, resizeCanvas]);

    // Load background image
    useEffect(() => {
      if (backgroundImageName) {
        backgroundImage.current = new Image();
        backgroundImage.current.src = backgroundImages[backgroundImageName];
        backgroundImage.current.onload = resizeCanvas;
      } else {
        resizeCanvas();
      }

      return () => {
        if (backgroundImage.current) {
          backgroundImage.current.onload = undefined;
        }
      };
    }, [backgroundImageName, resizeCanvas]);

    // Add resize event listener
    useEffect(() => {
      window.addEventListener("resize", resizeCanvas);

      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }, [resizeCanvas]);

    return <canvas ref={canvas} className="drawing-pad"></canvas>;
  }
);

DrawingPad.propTypes = {
  backgroundImageName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
