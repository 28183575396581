import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const VideoContext = createContext();

export const VideoContextProvider = ({ children }) => {
    const [room, setRoom] = useState(null);

    return (
        <VideoContext.Provider value={{ room, setRoom }}>
            {children}
        </VideoContext.Provider>
    );
};

VideoContextProvider.propTypes = {
    children: PropTypes.any,
};
