import { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { EventStatus } from "components/Events/event-status";
import { useFetchNextEvent } from "hooks/api/useFetchNextEvent";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { DashboardTile } from "../../shared/DashboardTile/DashboardTile";
import { NextEvent } from "./NextEvent/NextEvent";
import { NoEvent } from "./NoEvent/NoEvent";

export function NextEventTile({ setFetchError, studyLocked }) {
  const { t } = useTranslation();
  const { dispatch } = useContext(LoadingContext);

  const { event, error, isLoading } = useFetchNextEvent(
    () => {
      setFetchError("events.active_events", false);
    },
    () => {
      setFetchError("events.active_events", true);
    }
  );

  useEffect(() => {
    const LOADING_KEY = "next-event";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  const isActive = event?.status === EventStatus.ACTIVE;
  const disabled = !isActive;
  const hasEvent = !!event;
  const hasError = !!error;
  const noEvent = !hasError && !hasEvent;
  const testId = "next-event-tile";

  if (isLoading) {
    return <DashboardTile />;
  }

  return (
    <>
      {hasEvent && (
        <NextEvent
          disabled={disabled}
          hasError={hasError}
          isActive={isActive}
          event={event}
          testId={testId}
        />
      )}
      {noEvent && <NoEvent testId={testId} studyLocked={studyLocked} />}
      {hasError && (
        <DashboardTile
          disabled={disabled || hasError}
          hasError={hasError}
          testId={testId}
        >
          <h2>{t("home.next_event.upcoming_event")}</h2>
        </DashboardTile>
      )}
    </>
  );
}

NextEventTile.propTypes = {
  setFetchError: PropTypes.func.isRequired,
  studyLocked: PropTypes.bool.isRequired
};
