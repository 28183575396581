import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as WarningIcon } from "content/icons/warning.svg";
import { ReactComponent as SuccessIcon } from "content/icons/check.svg";

import "./StatusMessage.css";

export function StatusMessage({
  id,
  message,
  className,
  isError,
  isSuccess,
}) {
  return (
    <div
      id={id}
      className={classNames({
        "status-message": true,
        [className]: !!className,
        "success": !isError && isSuccess,
        "error": isError
      })}
      role="alert"
      aria-relevant="all">
        {isError && <WarningIcon />}
        {!isError && isSuccess && <SuccessIcon />}
        {message}
    </div>
  );
}

StatusMessage.defaultProps = {
  isError: false,
  isSuccess: false,
};

StatusMessage.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
};
