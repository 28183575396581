import PropTypes from "prop-types";
import classNames from "classnames";
import { useEffect } from "react";
import { createPortal } from "react-dom";

import "./Overlay.css";

const appRoot = document.getElementById("root");

export function Overlay({ children, minimized, visible }) {
  useEffect(() => {
    document.body.classList.toggle("display-overlay", visible);

    return () => {
      document.body.classList.remove("display-overlay");
    };
  }, [visible]);

  return createPortal(
    <div
      className={classNames({
        overlay: true,
        "overlay--minimized": minimized,
      })}
    >
      <div className="overlay__content">{children}</div>
    </div>,
    appRoot
  );
}

Overlay.defaultProps = {
  minimized: false,
  visible: false,
};

Overlay.propTypes = {
  children: PropTypes.any,
  minimized: PropTypes.bool,
  visible: PropTypes.bool,
};
