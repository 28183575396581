import { eraseCookie, getCookie, setCookie } from "helpers/cookies";
import { http } from "./http";

const getAccount = async () => {
    const account = await http.get(`/api/account`);
    setCookie(
        process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME,
        account.userMustChangePinCode
    );
    return account;
};

const getSettings = async () => {
    return await http.get(`/api/home/settings`);
};

const updateSettings = async (
    settings,
    allowedChangeReminderEmailSettings,
    allowedChangeReminderPhoneSettings
) => {
    return await http.post(`/api/home/settings`, {
        newPinCode: settings.newPinCode,
        newPinCodeRepeat: settings.newPinCodeRepeat,
        language: settings.language,
        email: settings.email,
        phone: settings.phone,
        sendRemindersToEmail: settings.sendRemindersToEmail,
        sendRemindersToPhone: settings.sendRemindersToPhone,
        trySaveReminderEmailSettings: allowedChangeReminderEmailSettings,
        trySaveReminderPhoneSettings: allowedChangeReminderPhoneSettings,
    });
};

const changePin = async (newPinCode, newPinCodeRepeat) => {
    const response = await http.put(`/api/account/change-pin`, {
        PinCode: newPinCode,
        Repeat: newPinCodeRepeat,
    });

    if (response && response.success) {
        eraseCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME);
    }
    return response;
};

const userMustChangePinCode = () => {
    return getCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME);
};

export const accountService = {
    getAccount,
    changePin,
    userMustChangePinCode,
    getSettings,
    updateSettings,
};
