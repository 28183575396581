import classNames from "classnames";
import PropTypes from "prop-types";

import "./GridSection.css";

export function GridSection({ className, children }) {
  return (
    <section
      className={classNames({
        grid: true,
        [className]: !!className,
      })}
    >
      {children}
    </section>
  );
}

GridSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
