import useSWR from "swr";

import { userEventsService } from "services/userEvents";

export const useFetchNextEvent = (onSuccess, onError) => {
    const { data, error } = useSWR(
        "/api/user/events/next",
        userEventsService.getNextEvent,
        {
            ...(!!onSuccess && { onSuccess: onSuccess }),
            ...(!!onError && { onError: onError }),
        }
    );

    // If data status is 204 (http "No Content"), there is no next event
    const event = data && data.status && data.status === 204 ? null : data;
    const isLoading = !error && data === undefined;

    return { event, error, isLoading };
};
