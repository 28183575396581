const defaultConfig = {
    confirmLabel: "Apply",
    clearLabel: "Reset",
    containerCSSClass: "",
    confirmButtonCSSClass: "",
    clearButtonCSSClass: "",
};

function confirmDatePlugin(pluginConfig) {
    const config = { ...defaultConfig, ...pluginConfig };

    return function (fp) {
        if (fp.isMobile) return {};

        function createButton(className, label, onClick) {
            const button = fp._createElement("button", className, label);

            button.addEventListener("click", onClick);

            return button;
        }

        return {
            onReady: function () {
                const container = fp._createElement(
                    "div",
                    `${config.containerCSSClass}`
                );

                const clearButton = createButton(
                    config.clearButtonCSSClass,
                    config.clearLabel,
                    () => {
                        fp.clear();
                        fp.close();
                    }
                );

                const confirmButton = createButton(
                    config.confirmButtonCSSClass,
                    config.confirmLabel,
                    fp.close
                );

                container.appendChild(clearButton);
                container.appendChild(confirmButton);

                fp.calendarContainer.appendChild(container);

                fp.loadedPlugins.push("confirmReset");
            },
        };
    };
}

export default confirmDatePlugin;
