import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useFetchEvents } from "hooks/api/useFetchEvents";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { DashboardBadge } from "../../shared/DashboardBadge/DashboardBadge";
import { DashboardTile } from "../../shared/DashboardTile/DashboardTile";
import { EventStatus } from "components/Events/event-status";

export function EventsTile({ setFetchError }) {
  const { t } = useTranslation();
  const { dispatch } = useContext(LoadingContext);

  const { events, error, isLoading } = useFetchEvents(
    () => {
      setFetchError("events.events", false);
    },
    () => {
      setFetchError("events.events", true);
    }
  );

  useEffect(() => {
    const LOADING_KEY = "events";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  const activeEventCount = events?.filter(
    (ev) => ev.status === EventStatus.ACTIVE
  ).length;

  return (
    <DashboardTile
      controls={() => (
        <>{!error && events && <DashboardBadge count={activeEventCount} />}</>
      )}
      disabled={!!error}
      hasError={!!error}
      page={`/events`}
      testId="events-tile"
    >
      <h2>{t("events.events")}</h2>
    </DashboardTile>
  );
}

EventsTile.propTypes = {
  setFetchError: PropTypes.func.isRequired,
};
