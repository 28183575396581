import { useEffect } from "react";
import { useLocation } from "react-router";

/**
 * Scrolls element in URL hash into view
 * @param {*} ref Ref to element
 * @param {*} id Unique identifier
 */
export function useHashScroll(ref, id) {
    const location = useLocation();

    useEffect(() => {
        if (ref.current && location.hash === `#${id}`) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "center",
            });
        }
    }, [id, location.hash, ref]);
}
