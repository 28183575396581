import PropTypes from "prop-types";
import classNames from "classnames";

import "./Input.css";

export function Input({
  ariaLabel,
  ariaDescribedBy,
  className,
  disabled,
  fullWidth,
  id,
  inputRef,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  type,
  valid,
  value,
}) {
  return (
    <input
      id={id}
      ref={inputRef}
      className={classNames({
        input: true,
        "input--invalid": !disabled && valid === false,
        "input--full-width": fullWidth,
        [className]: !!className,
      })}
      type={type}
      value={value ?? ""}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-invalid={!valid}
      aria-describedby={ariaDescribedBy}
    />
  );
}

Input.defaultProps = {
  fullWidth: true,
  value: "",
  type: "text",
};

Input.propTypes = {
  ariaLabel: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
