import PropTypes from "prop-types";
import { Fragment } from "react";

import { FormItemGroup } from "../FormItemGroup/FormItemGroup";

import "./FormContent.css";

export function FormContent({
  control,
  errors,
  formData,
  formHeaderRef,
  itemGroups,
  onAnimationEnd,
  onChange,
  onReset,
  readOnly,
}) {
  return (
    <>
      {itemGroups &&
        itemGroups.map((ig, i) => (
          <Fragment key={i}>
            <FormItemGroup
              control={control}
              errors={errors}
              formData={formData}
              formHeaderRef={formHeaderRef}
              itemGroup={ig}
              onAnimationEnd={onAnimationEnd}
              onChange={onChange}
              onReset={onReset}
              readOnly={readOnly}
            />
          </Fragment>
        ))}
    </>
  );
}

FormContent.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  formData: PropTypes.object,
  formHeaderRef: PropTypes.object,
  itemGroups: PropTypes.array,
  onAnimationEnd: PropTypes.func,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  readOnly: PropTypes.bool,
};
