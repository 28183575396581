import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";

import { LoadingContext, actionTypes } from "context/LoadingContext";
import { isPastEvent, isUpcomingEvent } from "components/Events/event-status";
import { useFetchEvents } from "hooks/api/useFetchEvents";

import { Box } from "components/Base/Box/Box";
import { BoxHeader } from "components/Base/Box/BoxHeader/BoxHeader";
import { BoxSection } from "components/Base/Box/BoxSection/BoxSection";
import { EventListItem } from "../EventListItem/EventListItem";
import { List } from "components/Base/List/List";
import { EventStatus } from "components/Events/event-status";
import { FetchError } from "components/Base/FetchError/FetchError";
import PropTypes from "prop-types";

import "./EventList.css";
import { StaggeredAnimation } from "components/Base/Box/StaggeredAnimation/StaggeredAnimation";

export function EventList({ studyLocked }) {
  const { t } = useTranslation();

  const { events, error, isLoading } = useFetchEvents();
  const { dispatch } = useContext(LoadingContext);
  const upcomingEvents = (events ?? []).filter(isUpcomingEvent).sort((a, b) => new Date(a.target) - new Date(b.target));
  const pastEvents = (events ?? []).filter(isPastEvent);
  const isUpcomingDisabled = (event) => event.status === EventStatus.SCHEDULED;
  const isPastDisabled = (event) => event.status === EventStatus.MISSING;

  useEffect(() => {
    const LOADING_KEY = "events";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <BoxHeader>
        {error ? (
          <FetchError />
        ) : (
          <h2 className="event-list__title">{t("events.events")}</h2>
        )}
      </BoxHeader>
      <StaggeredAnimation animationKey="events">
        {!studyLocked && (
          <BoxSection>
            {upcomingEvents.length === 0 ? (
              <p className="event-list__info">
                {t("events.no_upcoming_events")}
              </p>
            ) : (
              <>
                <h3 className="event-list__group-title">
                  {t("events.upcoming_events")}
                  <span>&nbsp;({upcomingEvents.length})</span>
                </h3>
                <List
                  isDisabled={isUpcomingDisabled}
                  items={upcomingEvents}
                  render={(event) => (
                    <EventListItem
                      disabled={isUpcomingDisabled(event)}
                      event={event}
                    />
                  )}
                />
              </>
            )}
          </BoxSection>
        )}
        <BoxSection>
          {pastEvents.length === 0 ? (
            <p className="event-list__info">{t("events.no_past_events")}</p>
          ) : (
            <>
              <h3 className="event-list__group-title">
                {t("events.past_events")}
                <span>&nbsp;({pastEvents.length})</span>
              </h3>
              <List
                isDisabled={isPastDisabled}
                items={pastEvents}
                render={(event) => (
                  <EventListItem
                    disabled={isPastDisabled(event)}
                    event={event}
                  />
                )}
              />
            </>
          )}
        </BoxSection>
      </StaggeredAnimation>
    </Box>
  );
}

EventList.propTypes = {
  studyLocked: PropTypes.bool.isRequired,
};
