import { documentService } from "services/document";
import useSWR from "swr";

export const useFetchDocuments = (onSuccess, onError) => {
    const { data, error } = useSWR(
        "/api/document/bindersummary",
        documentService.countDocuments,
        {
            ...(!!onSuccess && { onSuccess: onSuccess }),
            ...(!!onError && { onError: onError }),
        }
    );

    const isLoading = !error && !data;
    return { binderSummary: data, isLoading, error };
};