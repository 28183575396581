import { useTranslation } from "react-i18next";

import { DashboardTile } from "../../shared/DashboardTile/DashboardTile";
import startUnscheduledImg from "content/images/start_unscheduled.jpg";
import startUnscheduledVideo from "content/videos/unscheduled_event_bg.mp4";
import PropTypes from "prop-types";

import "./StartUnscheduledTile.css";
import { ArrowBadge } from "../../shared/ArrowBadge/ArrowBadge";

export function StartUnscheduledQuestionnaireTile({studyLocked}) {
  const { t } = useTranslation();

  return (
    <DashboardTile
      className="start-unscheduled"
      controls={() => (
        <ArrowBadge className="start-unscheduled__arrow" color="white" />
      )}
      page="/unscheduled-events"
      testId="start-unscheduled-tile"
    >
      <video
        autoPlay
        className="start-unscheduled__background"
        loop
        muted
        playsInline
        poster={startUnscheduledImg}
        tabIndex="-1"
      >
        <source src={startUnscheduledVideo} type="video/mp4" />
        <img
          src={startUnscheduledImg}
          alt={t("home.start_unscheduled_image")}
        />
      </video>
      <h2 className="start-unscheduled__title">
        {studyLocked ? t("home.start_unscheduled_when_locked") : t("home.start_unscheduled")}
      </h2>
    </DashboardTile>
  );
}

StartUnscheduledQuestionnaireTile.propTypes = {
  studyLocked: PropTypes.bool.isRequired
};
