import "./FetchError.css";

import { useTranslation } from "react-i18next";
import { ReactComponent as InfoIcon } from "content/icons/info.svg";

export function FetchError() {
  const { t } = useTranslation();

  return (
    <div className="fetch-error">
      <InfoIcon className="fetch-error__icon" />
      {t("general.errors.notification")} {t("general.errors.try_again")}
    </div>
  );
}
