import { getDeviceInformation } from "../components/Form/shared/form-helpers";
import { http } from "./http";

const getForm = (eventId) => http.get(`/api/quest/${eventId}`);
const getSubmittedForm = (eventId) => http.get(`/api/questfilled/${eventId}`);

const submitForm = (eventId, formData) =>
    http.post(`/api/quest/${eventId}`, { formData, ...getDeviceInformation() });
const uploadFile = (formData, progressHandler, abortCallback) =>
    http.postWithProgress("/api/studyfile", formData, progressHandler, abortCallback);
const mergeChunks = (path, formData) =>
    http.post(`/api/studyfile/${path}/merge-chunks`, formData);
const getFileInfo = (fileGuid) => http.get(`/api/studyfile/${fileGuid}`);
const downloadFile = (fileGuid) =>
    http.getFile(`/api/studyfile/${fileGuid}/download`);

export const formService = {
    getForm,
    getSubmittedForm,
    submitForm,
    uploadFile,
    mergeChunks,
    getFileInfo,
    downloadFile,
};
