import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./TextArea.css";

export const TextArea = forwardRef(
  (
    {
      ariaDescribedBy,
      ariaLabel,
      className,
      disabled,
      id,
      onBlur,
      onFocus,
      onChange,
      placeholder,
      valid,
      value,
    },
    ref
  ) => {
    return (
      <div
        className={classNames({
          "textarea-wrapper": true,
          "textarea-wrapper--invalid": !disabled && valid === false,
        })}
      >
        <textarea
          aria-describedby={ariaDescribedBy}
          aria-invalid={!valid}
          aria-label={ariaLabel}
          cols="50"
          className={classNames({
            textarea: true,
            [className]: !!className,
          })}
          disabled={disabled}
          id={id}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          rows="5"
          value={value ?? ""}
        />
      </div>
    );
  }
);

TextArea.defaultProps = {
  value: "",
};

TextArea.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
