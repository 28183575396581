import { createContext, useState } from "react";
import PropTypes from "prop-types";

import { authenticationService } from "services/authentication";

export const UserContext = createContext({
    authenticated: false,
    setAuthenticated: (authenticated) => (this.authenticated = authenticated),

    userMustChangePinCode: false,
    setUserMustChangePinCode: (userMustChangePinCode) =>
        (this.userMustChangePinCode = userMustChangePinCode),

    busyFormItems: [],
    setBusyFormItems: (busyFormItems) => (this.busyFormItems = busyFormItems),
});

export const UserContextProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(
        authenticationService.isLoggedIn()
    );
    const [userMustChangePinCode, setUserMustChangePinCode] = useState(false);
    const [busyFormItems, setBusyFormItems] = useState([]);

    const removeBusyFormItem = (oid) => {
        busyFormItems.includes(oid) &&
            setBusyFormItems(busyFormItems.filter((val) => val !== oid));
    };
    const addBusyFormItem = (oid) => {
        !busyFormItems.includes(oid) &&
            setBusyFormItems([...busyFormItems, oid]);
    };

    const userContextValue = {
        authenticated,
        setAuthenticated,
        userMustChangePinCode,
        setUserMustChangePinCode,
        busyFormItems,
        setBusyFormItems,
        addBusyFormItem,
        removeBusyFormItem,
    };

    return (
        <UserContext.Provider value={userContextValue}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.any,
};
