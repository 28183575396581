export const EventStatus = {
    MISSING: "Missing",
    RECEIVED: "Received",
    SCHEDULED: "Scheduled",
    ACTIVE: "Active",
};

export const isPastEvent = (event) =>
    event.status === EventStatus.MISSING ||
    event.status === EventStatus.RECEIVED;

export const isUpcomingEvent = (event) =>
    event.status === EventStatus.ACTIVE ||
    event.status === EventStatus.SCHEDULED;
