import "./NumberBadge.css";

import PropTypes from "prop-types";

export function NumberBadge({ count }) {
  return <div className="number-badge">{count}</div>;
}

NumberBadge.propTypes = {
  count: PropTypes.number,
};
