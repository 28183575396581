import { Route, useRouteMatch } from "react-router-dom";

import { EventList } from "./EventList/EventList";
import { Form } from "components/Form/Form";
import { UnscheduledEventList } from "./UnscheduledEventList/UnscheduledEventList";
import { useFetchHome } from "hooks/api/useFetchHome";

export function Events() {
  const { path } = useRouteMatch();
  const { isLoading, status } = useFetchHome();

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Route exact path={"/events"}>
        <EventList studyLocked={status.studyLock} />
      </Route>
      <Route exact path={"/unscheduled-events"}>
        <UnscheduledEventList studyLocked={status.studyLock} />
      </Route>
      <Route path={`${path}/:eventId/form/:page`}>
        <Form />
      </Route>
    </>
  );
}
