import { useTranslation } from "react-i18next";

import "./EventCompliance.css";

import PropTypes from "prop-types";
import classNames from "classnames";

export function EventCompliance({ percent, missedCount, studyLocked }) {
  const { t } = useTranslation();

  function activeStudy() {
    return (
      <>
        {missedCount ? (
          <>
            {t("home.event_compliance.percent", { percent })}
            <span className="event-compliance__missed-events">
              {` ${t("home.event_compliance.missed_events", {
                count: missedCount,
              })}`}
            </span>
          </>
        ) : (
          <>
            {t("home.event_compliance.zero_missed")}
            {percent === 100
              ? ` ${t("home.event_compliance.percent", { percent })}.`
              : ""}
          </>
        )}
      </>
    );
  }

  function lockedStudy() {
    return t("home.study_locked");
  }

  return (
    <small
      className={classNames({
        "event-compliance": true,
        "event-compliance--success": !missedCount,
      })}
    >
      {studyLocked ? lockedStudy() : activeStudy()}
    </small>
  );
}

EventCompliance.propTypes = {
  percent: PropTypes.number,
  missedCount: PropTypes.number,
  studyLocked: PropTypes.bool.isRequired,
};
