import PropTypes from "prop-types";
import classNames from "classnames";

import "./LabelInput.css";

export function LabelInput({
  id,
  label,
  value,
  type,
  pattern,
  className,
  disabled,
  required,
  valid,
  autoFocus,
  onChange,
  onBlur,
  ariaDescribedBy,
  inputRef,
}) {
  return (
    <div
      className={classNames({
        "label-input": true,
        [className]: !!className,
      })}
    >
      <input
        id={id}
        ref={inputRef}
        className={classNames({
          "label-input__input": true,
          "label-input__input--invalid": !disabled && valid === false,
        })}
        type={type}
        pattern={pattern}
        value={value ?? ""}
        placeholder=" "
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
        aria-required={required}
        aria-invalid={!valid}
        aria-describedby={ariaDescribedBy}
      />
      <label className="label-input__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

LabelInput.defaultProps = {
  value: "",
  type: "text",
};

LabelInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  pattern: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  ariaDescribedBy: PropTypes.string,
  inputRef: PropTypes.func,
};
