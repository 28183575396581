import "./FilePreview.css";
import classNames from "classnames";
import PropTypes from "prop-types";

export function FilePreview({ children, className, pending }) {
  return (
    <section
      className={classNames({
        "file-preview": true,
        "file-preview--pending": pending,
        [className]: !!className,
      })}
    >
      <div className="file-preview-content">{children}</div>
    </section>
  );
}

FilePreview.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  pending: PropTypes.bool,
};
