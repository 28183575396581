import PropTypes from "prop-types";
import { NumberBadge } from "../NumberBadge/NumberBadge";
import { ArrowBadge } from "../ArrowBadge/ArrowBadge";

import "./DashboardBadge.css";

export function DashboardBadge({ count }) {
  return count > 0 ? <NumberBadge count={count} /> : <ArrowBadge />;
}

DashboardBadge.propTypes = {
  count: PropTypes.number,
};
