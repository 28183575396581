import PropTypes from "prop-types";
import { List } from "components/Base/List/List";
import { UnansweredQuestionsListItem } from "../UnansweredQuestionsListItem/UnansweredQuestionsListItem";

export function UnansweredQuestionsList({ items, eventId }) {
  return (
    <>
      {items && (
        <List
          items={items}
          render={({ page, item }) => (
            <UnansweredQuestionsListItem
              eventId={eventId}
              page={page}
              oid={item.oid}
              label={item.exportLabel}
            />
          )}
        />
      )}
    </>
  );
}

UnansweredQuestionsList.propTypes = {
  items: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
};
