import { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import "./Box.css";

export function Box({ children, footer, showFooter }) {
  const nodeRef = useRef(null); // https://github.com/reactjs/react-transition-group/issues/668

  return (
    <div className="box">
      <div className="box__content">{children}</div>
      {footer && (
        <CSSTransition
          appear={true}
          classNames="box__footer"
          timeout={200}
          in={showFooter}
          nodeRef={nodeRef}
        >
          <div
            className={classNames({
              box__footer: true,
              "box__footer--hide": !showFooter,
            })}
            ref={nodeRef}
          >
            {footer}
          </div>
        </CSSTransition>
      )}
    </div>
  );
}

Box.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.any,
  showFooter: PropTypes.bool,
};
