import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowIcon } from "content/icons/arrow.svg";

import "./UnansweredQuestionsListItem.css";

export function UnansweredQuestionsListItem({ eventId, page, oid, label }) {
  const { t } = useTranslation();

  return (
    <RouterLink
      className="unanswered-question"
      to={`/events/${eventId}/form/${page}?validate=true#${oid}`}
    >
      <div>
        <div className="unanswered-question__header">
          {t("form.submit.unanswered_question")}
        </div>
        <h3 className="unanswered-question__label">{label}</h3>
      </div>
      <ArrowIcon />
    </RouterLink>
  );
}

UnansweredQuestionsListItem.propTypes = {
  eventId: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  oid: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
