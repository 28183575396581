export const isArrowKey = (keyName) =>
    keyName === "ArrowUp" ||
    keyName === "ArrowRight" ||
    keyName === "ArrowDown" ||
    keyName === "ArrowLeft";

export const isEnterOrSpace = (keyName) => isEnter(keyName) || isSpace(keyName);

export const isEnter = (keyName) => keyName === "Enter";

export const isSpace = (keyName) => keyName === " " || keyName === "Spacebar"; // Some older browsers use 'Spacebar'

/** Checks if keyName is ArrowUp or ArrowRight */
export const isIncrement = (keyName) =>
    keyName === "ArrowUp" || keyName === "ArrowRight";

/** Checks if keyName is ArrowDown or ArrowLeft */
export const isDecrement = (keyName) =>
    keyName === "ArrowDown" || keyName === "ArrowLeft";
