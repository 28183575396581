import PropTypes from "prop-types";
import { MainHeader } from "../MainHeader/MainHeader";
import { Footer } from "./Footer/Footer";
import "./MainLayout.css";

export function MainLayout({ children }) {
  return (
    <div className="main-layout">
      <div className="main-layout__content">
        <MainHeader />
        <main className="main-layout__inner-content">{children}</main>
        <Footer className="main-layout__footer" />
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};