import { isString } from "helpers/isString";

export const useTrimOnChange = (onChange) => {
    const getValue = (evtOrValue) => evtOrValue?.target?.value ?? evtOrValue;

    const handleChange = (value, trimFn) => {
        const actual = getValue(value);

        if (!isString(actual)) {
            throw Error(`Value must be a string. Was: ${actual}`);
        }

        onChange(trimFn(actual));
    };

    const trimAll = (evtOrValue) =>
        handleChange(evtOrValue, (value) => value.trim());

    const trimStart = (evtOrValue) =>
        handleChange(evtOrValue, (value) => value.trimStart());

    return { trimAll, trimStart };
};
