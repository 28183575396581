import PropTypes from "prop-types";
import classNames from "classnames";
import "./BoxSection.css";

export const BoxSectionBackground = {
  WHITE: "white",
  GREY: "grey",
};

export function BoxSection({
  backgroundColor,
  children,
  className,
  element: HtmlTag,
  onAnimationEnd,
  style,
}) {
  return (
    <HtmlTag
      className={classNames({
        [className]: className && className !== "",
        box__section: true,
        [`box__section--${backgroundColor}`]: true,
      })}
      style={style}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </HtmlTag>
  );
}

BoxSection.defaultProps = {
  backgroundColor: BoxSectionBackground.WHITE,
  element: "section",
};

BoxSection.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.values(BoxSectionBackground)),
  children: PropTypes.any,
  className: PropTypes.string,
  element: PropTypes.string,
  onAnimationEnd: PropTypes.func,
  style: PropTypes.object,
};
