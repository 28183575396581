import { wherebyService } from "services/whereby";
import useSWR from "swr";

export const useFetchIsRoomActive = (meetingId, refreshInterval, onSuccess) => {
    const fetcher = () => wherebyService.isRoomActive(meetingId);
    const { data: isRoomActive, error } = useSWR(
        `/api/whereby/isroomactive/${meetingId}`,
        fetcher,
        {
            refreshInterval: refreshInterval,
            onSuccess,
        }
    );

    return { isRoomActive, error };
};
