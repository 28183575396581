import { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { useFetchDocuments } from "hooks/api/useFetchDocuments";
import { useFetchStudyInfo } from "hooks/api/useFetchStudyInfo";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { DashboardBadge } from "../../shared/DashboardBadge/DashboardBadge";
import { DashboardTile } from "../../shared/DashboardTile/DashboardTile";

import { useTranslation } from "react-i18next";

export function DocumentsTile({ setFetchError }) {
  const { dispatch } = useContext(LoadingContext);

  const { studyInfo} = useFetchStudyInfo();
  const getInfo = (key) => studyInfo && studyInfo[key];

  const { t } = useTranslation();

  const { binderSummary, error, isLoading } = useFetchDocuments(
    () => {
      setFetchError("documents.documents", false);
    },
    () => {
      setFetchError("documents.documents", true);
    }
  );

  useEffect(() => {
    const LOADING_KEY = "documents";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  return (
    <>
    {
      binderSummary && binderSummary.bindersCount > 0 &&
      <DashboardTile
            controls={() => (
              <>{!error && binderSummary.totalBinderNeedTakenAction > 0 && <DashboardBadge count={binderSummary.totalBinderNeedTakenAction} />}</>
            )}
            disabled={!!error}
            hasError={!!error}
            page={`${process.env.REACT_APP_VIEDOC_SHARE_BASE_URL}/${getInfo("studyGuid")}`}
            testId="documents-tile"
            isExternalUrl={true}
            >
            <h2>{t("documents.documents")}</h2>
          </DashboardTile>
    }
    </>
    
  );
}

DocumentsTile.propTypes = {
  setFetchError: PropTypes.func.isRequired,
};