import { userEventsService } from "services/userEvents";
import useSWR from "swr";

export const useFetchEvents = (onSuccess, onError) => {
    const { data, error } = useSWR(
        "/api/user/events",
        userEventsService.getEvents,
        {
            ...(!!onSuccess && { onSuccess: onSuccess }),
            ...(!!onError && { onError: onError }),
        }
    );

    const isLoading = !error && !data;

    return { events: data, isLoading, error };
};
