import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTrimOnChange } from "hooks/useTrimOnChange";
import { isTouchDevice } from "helpers/isTouchDevice";
import { TextArea } from "components/Base/TextArea/TextArea";

export function ParagraphText({
  ariaDescribedBy,
  formHeaderRef,
  label,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const { trimAll, trimStart } = useTrimOnChange(onChange);

  const textArea = useRef(null);

  // Fixes issue with keybord on Android pushing text area below fixed form header
  const makeTextAreaVisible = (e) => {
    setTimeout(() => {
      const textAreaHasFocus = document.activeElement === textArea.current;

      if (!textAreaHasFocus) {
        return;
      }

      const scrollOffset = 10; // adds some extra space between header and text area
      const textAreaTop = textArea.current.offsetTop;
      const boxHeaderBottom =
        formHeaderRef && formHeaderRef.current
          ? formHeaderRef.current.offsetTop + formHeaderRef.current.offsetHeight
          : 0;

      const isTextAreaBelowHeader = textAreaTop < boxHeaderBottom;

      if (isTextAreaBelowHeader) {
        window.scrollTo({
          top: textAreaTop - formHeaderRef.current.offsetHeight - scrollOffset,
          behavior: "smooth",
        });
      }
    }, 100); // ~100ms delay is needed for the layout changes to have happened when the keyboard is shown
  };

  return (
    <TextArea
      ariaDescribedBy={ariaDescribedBy}
      ariaLabel={label}
      disabled={readOnly}
      id={oid}
      onBlur={trimAll}
      onChange={trimStart}
      onFocus={() => {
        if (isTouchDevice()) {
          window.addEventListener("resize", makeTextAreaVisible, {
            once: true,
          }); // Resize event is triggered when mobile keyboard is shown
        }
      }}
      ref={textArea}
      valid={valid}
      value={value}
    />
  );
}

ParagraphText.propTypes = {
  ariaDescribedBy: PropTypes.string,
  formHeaderRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  label: PropTypes.string,
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
