import { authenticationService } from 'services/authentication';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAsyncError } from 'hooks/useAsyncError';

export function Login() {
  const location = useLocation();
  const throwError = useAsyncError();

  useEffect(() => {
    async function loginRedirect() {
        try {
          await authenticationService.onLoginRedirect(location.state && location.state.loginHint);
        } catch (e) {
          throwError(e);
        }
    }

    loginRedirect();
  }, [location.state, throwError]);

  return (
    <></>
  );
}