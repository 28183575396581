import "./NextEvent.css";

import PropTypes from "prop-types";
import classNames from "classnames";
import dateIsTomorrow from "date-fns/isTomorrow";
import { useTranslation } from "react-i18next";

import { DashboardTile } from "components/Home/Dashboard/shared/DashboardTile/DashboardTile";
import { Countdown } from "../Countdown/Countdown";
import { NextEventDetails } from "../NextEventDetails/NextEventDetails";

import { toPrettyLocaleDateString } from "helpers/date-helpers";
import { EventStatus } from "components/Events/event-status";
import { ArrowBadge } from "components/Home/Dashboard/shared/ArrowBadge/ArrowBadge";

export function NextEvent({ disabled, hasError, isActive, event, testId }) {
  const { t } = useTranslation();

  const isTomorrow =
    event.status === EventStatus.SCHEDULED &&
    dateIsTomorrow(new Date(event.target));

  const getEventDayOrDate = () => {
    if (isActive) return t("home.next_event.today");
    if (isTomorrow) return t("home.next_event.tomorrow");

    return toPrettyLocaleDateString(event.target, {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <DashboardTile
      className={classNames({
        "next-event": true,
        "next-event--active": isActive,
      })}
      disabled={disabled || hasError}
      hasError={hasError}
      page={`/events/${event.formId}/form/1`}
      testId={testId}
      controls={() => (
        <>
          {isActive && <ArrowBadge color="green" />}
          {isTomorrow && <Countdown end={event?.target} />}
        </>
      )}
    >
      <div className="next-event__content">
        <div className="next-event__heading">
          <small className="next-event__title">
            {t("home.next_event.upcoming_event")}
          </small>
          <h2>{getEventDayOrDate()}</h2>
        </div>
        <NextEventDetails
          availableEnd={event.availableEnd}
          availableStart={event.availableStart}
          formName={event.formName}
          isFuture={!isActive && !isTomorrow}
          target={event.target}
          timingWindowEnd={event.timingWindowEnd}
          timingWindowStart={event.timingWindowStart}
        />
      </div>
    </DashboardTile>
  );
}

NextEvent.propTypes = {
  disabled: PropTypes.bool,
  event: PropTypes.object,
  hasError: PropTypes.bool,
  isActive: PropTypes.bool,
  testId: PropTypes.string,
};

NextEvent.defaultProps = {
  disabled: false,
};
