import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsightsTelemetry } from "ApplicationInsightsService";
import PropTypes from "prop-types";
import React, { Component } from "react";

import "./ErrorBoundary.css";
import { getCookie } from "helpers/cookies";

const aiCookies = Object.freeze({
  user: "ai_user",
  session: "ai_session",
});

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorStack: null,
      errorTime: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      errorStack: error.stack,
      errorTime: new Date().toISOString(),
    };
  }

  componentDidCatch(error, errorInfo) {
    if (appInsightsTelemetry) {
      appInsightsTelemetry.trackException({
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: errorInfo,
      });
    }
  }

  render() {
    const aiSessionId = getCookie(aiCookies.session);
    const aiUserId = getCookie(aiCookies.user);

    if (this.state.errorStack) {
      return (
        <div className="error-boundary">
          <h1>Something went wrong.</h1>
          <p>Please refresh the page and try again.</p>

          <pre>
            <dl>
              <dt>Time:</dt>
              <dd>{this.state.errorTime}</dd>
              <dt>Log information:</dt>
              <dd>{aiUserId ?? "N/A"}</dd>
              <dd>{aiSessionId && aiSessionId}</dd>
            </dl>
            <code>{this.state.errorStack}</code>
          </pre>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
