import React, { useRef } from "react";
import PropTypes from "prop-types";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import "./StaggeredAnimation.css";

export function StaggeredAnimation({ animationKey, children, onAnimationEnd }) {
  const childList = React.Children.toArray(children);
  const nodeRef = useRef(null); // https://github.com/reactjs/react-transition-group/issues/668

  return (
    <SwitchTransition>
      <CSSTransition
        key={animationKey}
        appear={true}
        timeout={400}
        classNames="staggered-animation"
        nodeRef={nodeRef}
      >
        <div className="staggered-animation" ref={nodeRef}>
          {childList
            .filter((child) => !!child) // filter out empty children
            .map((child, i, visibleChildren) => {
              const className = child.props.className
                ? `${child.props.className} staggered-animation__item`
                : "staggered-animation__item";

              const isLastChild = i === visibleChildren.length - 1;

              return React.cloneElement(child, {
                className,
                style: { transform: `translateY(${(i + 1) * 2}rem)` },
                ...(isLastChild && onAnimationEnd && { onAnimationEnd }),
              });
            })}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

StaggeredAnimation.propTypes = {
  children: PropTypes.node,
  animationKey: PropTypes.string.isRequired,
  onAnimationEnd: PropTypes.func,
};
