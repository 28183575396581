import { useState, useCallback } from "react";

/**
 * Custom hook that allows throwing errors from an async call.
 * This hook is useful for handling errors in components that use Error Boundaries.
 *
 * @returns {Function} A callback function that takes an error as an argument and throws it.
 *
 * @example
 * const throwError = useAsyncError();
 * 
 * useEffect(() => {
 *   async function fetchData() {
 *     try {
 *       // some async operation
 *     } catch (error) {
 *       throwError(error);
 *     }
 *   }
 *   fetchData();
 * }, [throwError]);
 */

export const useAsyncError = () => {
    const [, setError] = useState();

    return useCallback(
        (error) => {
            setError(() => {
                throw error;
            });
        },
        [setError]
    );
};