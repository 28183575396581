import useSWR from "swr";

import { formService } from "services/form";

export const useSubmittedForm = (formId, onSuccess) => {
    const fetcher = () => formService.getSubmittedForm(formId);

    const { data, error } = useSWR(`/api/QuestFilled/${formId}`, fetcher, {
        revalidateOnFocus: false,
        onSuccess,
    });

    const isLoading = !error && !data;

    return { data, isLoading, error };
};
