import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./LoadingIndicator.css";

function LoadingIndicatorRect({ x, y }) {
  return (
    <rect
      width="6"
      height="6"
      x={x}
      y={y}
      className="loading-indicator__rect"
    ></rect>
  );
}

LoadingIndicatorRect.propTypes = {
  x: PropTypes.string.isRequired,
  y: PropTypes.string.isRequired,
};

export function LoadingIndicator({ className }) {
  return (
    <svg
      className={classNames({
        "loading-indicator": true,
        [className]: !!className,
      })}
      viewBox="0 0 44 44"
      width="100%"
      height="100%"
      role="status"
    >
      <LoadingIndicatorRect x="2" y="2" />
      <LoadingIndicatorRect x="36" y="2" />
      <LoadingIndicatorRect x="36" y="36" />
      <LoadingIndicatorRect x="2" y="36" />
    </svg>
  );
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};
