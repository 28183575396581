import PropTypes from "prop-types";
import { HtmlText } from "components/Base/HtmlText/HtmlText";

import "./StaticText.css";

export function StaticText({ label }) {
  return <HtmlText className="static-text" element="p" htmlString={label} />;
}

StaticText.propTypes = {
  label: PropTypes.string,
};
