import PropTypes from "prop-types";
import classNames from "classnames";

import "./ListItem.css";

export function ListItem({ children, disabled }) {
  return (
    <li
      className={classNames({
        "list-item": true,
        "list-item--disabled": disabled,
      })}
    >
      {children}
    </li>
  );
}

ListItem.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
};
