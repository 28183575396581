import PropTypes from "prop-types";
import classNames from "classnames";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { ReactComponent as ArrowIcon } from "content/icons/arrow.svg";
import confirmResetPlugin from "./ConfirmResetPlugin";
import { getDateTimePickerLocale } from "./getDateTimePickerLocale";

import "./DateTimePicker.css";

export function DateTimePicker({
  ariaDescribedBy,
  ariaLabel,
  className,
  disabled,
  onChange,
  options,
  placeholder,
  valid,
  value,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [locale, setLocale] = useState();
  const fp = useRef(null);

  const getFormatDate = (mergedOptions) => {
    const timeStyle = mergedOptions.enableTime ? { timeStyle: "short" } : {};
    const dateStyle = mergedOptions.noCalendar ? {} : { dateStyle: "short" };

    const o = new Intl.DateTimeFormat([], {
      ...timeStyle,
      ...dateStyle,
    });

    return (date) => o.format(date);
  };

  useEffect(() => {
    const updateLocale = async () => {
      const locale = await getDateTimePickerLocale(language);
      setLocale(locale);
    };

    updateLocale();
  }, [language]);
  const defaultOptions = {
    ...(locale && { locale }),
    closeOnSelect: false,
    enableTime: true,
    defaultHour: new Date().getHours(),
    defaultMinute: new Date().getMinutes(),
    time_24hr: true,
    onReady: (a, b, fp) => {
      fp.element.setAttribute("aria-label", ariaLabel);
      fp.element.setAttribute("aria-describedby", ariaDescribedBy);
    },
    onOpen: () => {
      // Errors below are handled natively on mobiles, so we can exit early
      if (fp.current.flatpickr.isMobile) {
        return;
      }

      const now = new Date();

      // Current time is set on component creation and not updated correctly
      // in the datepicker, thus the time of the first page render is still
      // displayed when opened and no time is selected by user. To fix this we
      // need to manually update this property on the flatpickr object
      if (!fp.current.flatpickr.latestSelectedDateObj) {
        fp.current.flatpickr._setHoursFromDate(now);
      }

      // Current date is set on component creation and not updated correctly
      // if you keep the page open when the date changes (during midnight).
      // To fix this we need to check if the "today" element no longer matches
      // the current date and then manually update the "now" property and redraw the UI element
      const todayElement = fp.current.flatpickr.days?.querySelector(".today");
      if (todayElement && todayElement.innerText !== now.getDate().toString()) {
        fp.current.flatpickr.now = now;
        fp.current.flatpickr.redraw();
      }
    },
    minuteIncrement: 1,
    plugins: [
      new confirmResetPlugin({
        confirmLabel: t("form.date_input.apply"),
        clearLabel: t("form.date_input.reset"),
        containerCSSClass: "date-time-picker__actions",
        confirmButtonCSSClass: "button button--green button--small",
        clearButtonCSSClass:
          "button button--transparent date-time-picker__reset-button",
      }),
    ],
  };

  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  return (
    <Flatpickr
      ref={fp}
      key={`enable-time-${options.enableTime ? "true" : "false"}`} // tricks react into recreating the flatpickr component
      value={value}
      onChange={([date]) => onChange(date)}
      options={{ ...mergedOptions, formatDate: getFormatDate(mergedOptions) }}
      render={({ value }, ref) => (
        <div
          className={classNames({
            [className]: !!className,
            "date-time-picker": true,
            "date-time-picker--selected": value,
            "date-time-picker--error": !valid,
          })}
        >
          <input
            ref={ref}
            className="date-time-picker__input"
            readOnly
            value={value instanceof Date ? value.toISOString() : value ?? ""}
            placeholder={placeholder}
            disabled={disabled}
            aria-invalid={!valid}
          />
          <ArrowIcon className="date-time-picker__icon" />
        </div>
      )}
    ></Flatpickr>
  );
}

DateTimePicker.defaultProps = {
  valid: true,
  disabled: false,
};

DateTimePicker.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};
