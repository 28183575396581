import useSWR from "swr";

import { metaService } from "services/meta";

export const useFetchFooter = () => {
    const { data: footerDetails, error } = useSWR(
        "/api/meta/footer",
        metaService.getFooterDetails,
        {
            revalidateOnFocus: false,
        }
    );

    const isLoading = !error && !footerDetails;

    return { footerDetails, isLoading, error };
};
