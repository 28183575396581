import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./CircularProgress.css";

export function CircularProgress({
  className,
  color,
  size,
  strokeWidth,
  success,
  value,
}) {
  const animationDuration = 600;
  const diameter = 30;
  const center = diameter / 2;
  const radius = (diameter - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const valueOffset = ((100 - value) / 100) * circumference;

  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (value === 100) {
      const timer = setTimeout(() => {
        setIsComplete(true);
      }, animationDuration);
      return () => clearTimeout(timer);
    } else {
      setIsComplete(false);
    }
  }, [value]);

  return (
    <svg
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
      className={classNames({
        "circular-progress": true,
        [`circular-progress--${size}`]: true,
        "circular-progress--success": isComplete && success,
        [className]: !!className,
      })}
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="circular-progress__background"
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        fill="transparent"
      />
      <circle
        className={classNames({
          "circular-progress__value": true,
          [`circular-progress__value--${color}`]: !!color,
        })}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        fill="transparent"
        strokeDasharray={circumference}
        strokeDashoffset={valueOffset}
      />
    </svg>
  );
}

CircularProgress.defaultProps = {
  color: "black",
  size: "small",
  strokeWidth: 2,
};

CircularProgress.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["blue", "black", "green"]),
  size: PropTypes.oneOf(["small", "large"]).isRequired,
  strokeWidth: PropTypes.number,
  success: PropTypes.bool,
  value: PropTypes.number.isRequired,
};
