import PropTypes from "prop-types";
import { VasScale } from "./VasScale/VasScale";

import "./VasScaleItem.css";

export function VasScaleItem({
  ariaDescribedBy,
  label,
  layout,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const {
    numericFeedbackLabel,
    orientation,
    showLineScale,
    showMinMaxLabels,
    showNumberScale,
    showNumericFeedback,
    vasColor,
    vasLegendLabels,
  } = layout;

  return (
    <div className="vas-scale-item">
      <VasScale
        ariaDescribedBy={ariaDescribedBy}
        fullColor={vasColor !== "gray"}
        id={oid}
        lineScale={showLineScale}
        numericFeedbackLabel={numericFeedbackLabel}
        numericScale={showNumberScale}
        onChange={onChange}
        readOnly={readOnly}
        rtl={window.getComputedStyle(document.body).direction === "rtl"}
        showMinMaxLabels={showMinMaxLabels}
        showNumericFeedback={showNumericFeedback}
        valid={valid}
        value={value}
        vasLegendLabels={vasLegendLabels}
        vertical={orientation === "vertical"}
      />
    </div>
  );
}

VasScaleItem.propTypes = {
  ariaDescribedBy: PropTypes.string,
  label: PropTypes.string,
  layout: PropTypes.shape({
    numericFeedbackLabel: PropTypes.string,
    orientation: PropTypes.oneOf(["vertical", "horizontal"]),
    showLineScale: PropTypes.bool,
    showMinMaxLabels: PropTypes.bool,
    showNumberScale: PropTypes.bool,
    showNumericFeedback: PropTypes.bool,
    vasColor: PropTypes.oneOf(["gray", "fullcolor"]),
    vasLegendLabels: PropTypes.array,
  }),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.number,
};
