import classNames from "classnames";
import PropTypes from "prop-types";
import "./VideoAction.css";

export const VideoActionSize = {
  DEFAULT: "default",
  LARGE: "large",
};

export function VideoAction({ children, className, onClick, size }) {
  return (
    <div
      className={classNames({
        [className]: className !== "",
        "video-action": true,
        [`video-action--${size}`]: size !== VideoActionSize.DEFAULT,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

VideoAction.defaultProps = {
  className: "",
  size: VideoActionSize.DEFAULT,
};

VideoAction.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(VideoActionSize)).isRequired,
};
