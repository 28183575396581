import useSWR from "swr";

import { userEventsService } from "services/userEvents";

export const useFetchUnscheduledEvents = () => {
    const { data, error } = useSWR(
        "/api/user/events/unscheduled",
        userEventsService.getUnscheduledEvents
    );

    const isLoading = !error && !data;

    return { events: data, isLoading, error };
};
