import { studyService } from "services/study";
import useSWR from "swr";

export const useFetchLanguages = () => {
  const { data: accountLanguages, error } = useSWR('/api/study/languages', studyService.getLanguages, {
    revalidateOnFocus: false
  });

  const isLoading = !error && !accountLanguages;

  return { accountLanguages, isLoading, error };
};