import "./FormSubmitPage.css";

import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { BoxSection } from "components/Base/Box/BoxSection/BoxSection";
import { FormSubmitPageErrors } from "./FormSubmitPageErrors/FormSubmitPageErrors";

import { getItemsWithoutValue } from "../shared/form-helpers";
import { StaggeredAnimation } from "components/Base/Box/StaggeredAnimation/StaggeredAnimation";

export function FormSubmitPage({
  itemGroups,
  formData,
  onAnimationEnd,
  onSubmitValidation,
  submitFailed,
  submitErrorMessage,
}) {
  const { t } = useTranslation();
  const { eventId } = useParams();

  let [itemsWithoutValue, setItemsWithoutValue] = useState();

  const formHasErrors = itemsWithoutValue && itemsWithoutValue.length > 0;

  useEffect(() => {
    // Because of "backwards visibility conditions" the form
    // is checked for required questions left unanswered.
    const itemsWithoutValue = getItemsWithoutValue(itemGroups, formData);
    setItemsWithoutValue(itemsWithoutValue);
    onSubmitValidation(itemsWithoutValue.length === 0);
  }, [formData, itemGroups, onSubmitValidation]);

  return (
    <StaggeredAnimation animationKey="submit" onAnimationEnd={onAnimationEnd}>
      <BoxSection>
        {formHasErrors && (
          <FormSubmitPageErrors
            eventId={eventId}
            itemsWithoutValue={itemsWithoutValue}
          />
        )}
        {submitFailed && (
          <div className="form-submit__submit-info">
            {submitErrorMessage ?? t("form.submit.submit_failed")}
          </div>
        )}
        {!formHasErrors && !submitFailed && (
          <div className="form-submit__submit-info">
            <h3 className="form-submit__title">{t("form.submit.title")}</h3>
            {t("form.submit.submit_info")}
          </div>
        )}
      </BoxSection>
    </StaggeredAnimation>
  );
}

FormSubmitPage.propTypes = {
  itemGroups: PropTypes.array,
  formData: PropTypes.object,
  onAnimationEnd: PropTypes.func.isRequired,
  onSubmitValidation: PropTypes.func,
  submitFailed: PropTypes.bool,
  submitErrorMessage: PropTypes.string,
};
