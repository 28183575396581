import classNames from "classnames";
import PropTypes from "prop-types";
import "./VideoControls.css";

export function VideoControls({ children, minimized }) {
  return (
    <div
      className={classNames({
        "video-controls": true,
        "video-controls--minimized": minimized,
      })}
    >
      {children}
    </div>
  );
}

VideoControls.propTypes = {
  children: PropTypes.any,
  minimized: PropTypes.bool,
};
