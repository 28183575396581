import PropTypes from "prop-types";
import { createPortal } from "react-dom";

export function ModalPortal({ children, wrapperId }) {
  return createPortal(children, document.getElementById(wrapperId));
}

ModalPortal.propTypes = {
  children: PropTypes.any,
  wrapperId: PropTypes.string.isRequired,
};
