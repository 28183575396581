import "./App.css";
import "components/viedocui/index";

import React, { useEffect, useContext, useState } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./localization/i18n";
import { UserContextProvider } from "context/UserContext";
import { VideoContext, VideoContextProvider } from "context/VideoContext";
import {
    LoadingContext,
    LoadingContextProvider,
    actionTypes,
} from "context/LoadingContext";
import { history } from "router/history";
import { useLoadingOverlayDelay } from "hooks/useLoadingOverlayDelay";
import { useFetchRoom } from "hooks/api/useFetchRoom";

import { ChangePin } from "components/ChangePin/ChangePin";
import { ErrorBoundary } from "components/Base/ErrorBoundary/ErrorBoundary";
import { Events } from "components/Events/Events";
import { Home } from "components/Home/Home";
import { MainLayout } from "./components/Layout/MainLayout";
import { NotFound } from "./components/NotFound/NotFound";
import { ProtectedRoute } from "./components/Base/ProtectedRoute/ProtectedRoute";
import { PublicLayout } from "./components/Layout/PublicLayout";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";
import { Settings } from "components/Settings/Settings";
import { StudyInfo } from "components/StudyInfo/StudyInfo";
import { Pdf } from "components/Pdf/Pdf";
import { LoadingOverlay } from "components/Base/LoadingOverlay/LoadingOverlay";
import { VideoOverlay } from "components/VideoOverlay/VideoOverlay";

import SigninOidc from 'components/Login/signin-oidc'
import { Login } from "components/Login/Login";

import SignoutOidc from 'components/Logout/signout-oidc'
import { Logout } from "components/Logout/logout";

import { authenticationService } from "services/authentication";

import { Welcome } from "components/Welcome/Welcome";

import { UserContext } from "context/UserContext";

import {
    ShowLoadingContext,
    ShowLoadingContextProvider,
} from "context/ShowLoadingContext";

import { IDENTITY_CONFIG } from "utils/identityConfig";

const KEEP_USER_ACTIVE_INTERVAL = 10 * 1000; // 10s

function Main() {
    const { i18n, ready: translationsReady } = useTranslation(undefined, {
        useSuspense: false,
    });

    const {busyFormItems} = useContext(UserContext);

    const { room } = useContext(VideoContext);
    const { isLoading, dispatch } = useContext(LoadingContext);
    const { showLoading, setShowLoading } = useContext(ShowLoadingContext);
    const showLoadingOverlay = useLoadingOverlayDelay(isLoading);

    const { pathname } = useLocation();
    const isPdfMode = pathname.startsWith("/pdf/");
    const { room: activeRoom } = useFetchRoom({ shouldFetch: !isPdfMode });
    const isAuthenticated = authenticationService.isLoggedIn();

    useEffect(() => {
        setShowLoading(showLoadingOverlay);
    }, [setShowLoading, showLoadingOverlay]);

    useEffect(() => {
        const LOADING_KEY = "translations";
        const type = translationsReady
            ? actionTypes.REMOVE_LOADING_ITEM
            : actionTypes.ADD_LOADING_ITEM;

        dispatch({
            type,
            key: LOADING_KEY,
        });
    }, [dispatch, translationsReady]);

    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.language;

    const [isKeepUserActive, setKeepUserActive] = useState(false);

    useEffect(() => {
        setKeepUserActive(busyFormItems.length > 0);
    }, [busyFormItems]);

    const inactivityTracker = document.getElementsByTagName('viedoc-inactivity-tracker-cross-domain')[0];
    useEffect(() => {
        if(inactivityTracker){
            inactivityTracker.tokenCallback = () => { return authenticationService.getLocalToken(); };
            inactivityTracker.logoutCallback = () => { return authenticationService.logout(); };
            return () => {
              delete inactivityTracker.tokenCallback;
              delete inactivityTracker.logoutCallback;
            };
        }
      }, [inactivityTracker]); 

    const heartBeatUrl = `${IDENTITY_CONFIG.authority}/api/heartbeat` 

    return (
        <ErrorBoundary>
            {isAuthenticated && !isPdfMode && 
            <viedoc-inactivity-tracker-cross-domain 
                heartbeat-url={heartBeatUrl}
                is-single-page
                hot-reload
                keep-alive={isKeepUserActive || null}
                keep-user-active-interval={isKeepUserActive ? KEEP_USER_ACTIVE_INTERVAL : null}>
            </viedoc-inactivity-tracker-cross-domain>}
            <LoadingOverlay show={showLoading} />
            {translationsReady && (
                <Switch>
                    <Route path="/signout-oidc" component={SignoutOidc} />
                    <Route path="/signin-oidc" component={SigninOidc} />
                    <Route path="/logout" component={Logout} />
                    <Route path={["/login","/change-pin", "/welcome"]}>
                        <PublicLayout>
                            <ErrorBoundary>
                                <Switch>
                                    <Route path="/login" component={Login}/>
                                    <ProtectedRoute
                                        path="/change-pin"
                                        component={ChangePin}
                                    />
                                    <ProtectedRoute
                                        path="/welcome"
                                        component={Welcome}
                                    />
                                </Switch>
                            </ErrorBoundary>
                        </PublicLayout>
                    </Route>
                    <ProtectedRoute path="/pdf/:formId">
                        <Pdf />
                    </ProtectedRoute>
                    <Route path="/">
                    <VideoOverlay room={room} activeRoom={activeRoom} />
                        <MainLayout>
                            <ErrorBoundary>
                                <Switch>
                                    <ProtectedRoute
                                        path="/"
                                        component={Home}
                                        exact
                                    />
                                    <ProtectedRoute
                                        path="/study"
                                        component={StudyInfo}
                                    />
                                    <ProtectedRoute
                                        path="/events"
                                        component={Events}
                                    />
                                    <ProtectedRoute
                                        path="/unscheduled-events"
                                        component={Events}
                                    />
                                    <ProtectedRoute
                                        path="/settings"
                                        component={Settings}
                                    />
                                    <Route component={NotFound} />
                                </Switch>
                            </ErrorBoundary>
                        </MainLayout>
                    </Route>
                </Switch>
            )}
        </ErrorBoundary>
    );
}

export function App() {
    return (
        <ErrorBoundary>
            <Router history={history}>
                <LoadingContextProvider>
                    <ShowLoadingContextProvider>
                        <VideoContextProvider>
                            <UserContextProvider>
                                <ScrollToTop />
                                <Main />
                            </UserContextProvider>
                        </VideoContextProvider>
                    </ShowLoadingContextProvider>
                </LoadingContextProvider>
            </Router>
        </ErrorBoundary>
    );
}