import { i18n } from "localization/i18n";
import { isDateTimeItem } from "./form-helpers";

const getI18nKey = (ruleName) => `validation.${ruleName}`;

const getI18nMessage = (ruleName, ruleArg) =>
    i18n.t(getI18nKey(ruleName), { [ruleName]: ruleArg });

const customValidators = {
    minLengthArray: (value, ruleName, ruleArg) => {
        /* Only validate the length of the value if a value is selected
          since it should be possible to leave it empty when the item is optional */
        if ((!value && ruleArg) || (value.length && value.length < ruleArg)) {
            return getI18nMessage(ruleName, ruleArg);
        }
    },
    maxLengthArray: (value, ruleName, ruleArg) => {
        if (value && value.length >= ruleArg) {
            return getI18nMessage(ruleName);
        }
    },
    precision: (value, ruleName, ruleArg) => {
        if (!value) return;
        const { allowedDecimals, maxLength } = ruleArg;

        const [integerPart, decimalPart] = value.toString().split(".");

        const hasDecimals = decimalPart && decimalPart.length;

        if (hasDecimals && allowedDecimals === 0) {
            return getI18nMessage("integer", allowedDecimals);
        }

        if (hasDecimals && allowedDecimals < decimalPart.length) {
            return getI18nMessage("decimal", allowedDecimals);
        }

        const maxIntegerDigits = maxLength - allowedDecimals;

        if (integerPart && integerPart.length > maxIntegerDigits) {
            return allowedDecimals === 0
                ? getI18nMessage("maxDigits", maxIntegerDigits)
                : getI18nMessage("maxDigitsDecimal", maxIntegerDigits);
        }
    },
};

const getRuleName = (ruleName) => {
    if (ruleName === "minValue") {
        return "min";
    }

    if (ruleName === "maxValue") {
        return "max";
    }

    return ruleName;
};

const getRule = (ruleName, ruleArg, ruleKey) => {
    return {
        [ruleName]: {
            value: ruleArg,
            message: getI18nMessage(ruleKey, ruleArg),
        },
    };
};

const getCustomValidator = (ruleName, ruleArg) => (value) =>
    customValidators[ruleName](value, ruleName, ruleArg);

const getCustomRule = (customRuleName, ruleArg) => {
    return {
        validate: {
            [customRuleName]: getCustomValidator(customRuleName, ruleArg),
        },
    };
};

const isInvalidRule = (clinicRuleName, ruleArg, itemType) =>
    ruleArg == null ||
    clinicRuleName === "rangeChecks" || // Not supported in Viedoc Me
    (clinicRuleName === "maxValue" && isDateTimeItem(itemType)); // Is restricted by disabling dates instead

const parseRuleArg = (ruleArg) => {
    const parsedValue = parseInt(ruleArg);
    return Number.isInteger(parsedValue) ? parsedValue : ruleArg;
};

const convertItemValidation = (item) => {
    let rules = Object.entries(item.validation).reduce(
        (rules, [clinicRuleName, ruleArg]) => {
            if (isInvalidRule(clinicRuleName, ruleArg, item.itemType)) {
                return rules;
            }

            let ruleName = getRuleName(clinicRuleName);
            let ruleKey = ruleName;

            // Ensure to capture both empty and invalid number in required number error message
            if (item.itemType === "Number" && ruleName === "required") {
                ruleKey = "required_number";
            }

            const parsedRuleArg = parseRuleArg(ruleArg);

            const isArrayLengthRule =
                item.itemType === "Checkboxes" &&
                (ruleName === "minLength" || ruleName === "maxLength");

            const rule = isArrayLengthRule
                ? getCustomRule(`${ruleName}Array`, parsedRuleArg)
                : getRule(ruleName, parsedRuleArg, ruleKey);

            return {
                ...rules,
                ...rule,
            };
        },
        {}
    );

    if (item.itemType === "Number") {
        rules = {
            ...rules,
            ...getCustomRule("precision", {
                maxLength: 12,
                allowedDecimals: parseRuleArg(item.decimals),
            }),
        };
    }

    return rules;
};

/**
 * Converts validation from Viedoc into react-hook-form compatible rules
 * @param {*} form
 */
export const convertFormValidation = (form) => {
    for (const itemGroup of form.itemGroups) {
        for (const item of itemGroup.items) {
            item.reactHookFormValidation = convertItemValidation(item);
        }
    }
};
