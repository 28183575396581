import PropTypes from "prop-types";

import { ListItem } from "../ListItem/ListItem";

import "./List.css";

export function List({ isDisabled, items, render }) {
  return (
    <ul className="list">
      {items.map((item, i) => {
        const disabled = isDisabled ? isDisabled(item) : false;

        return (
          <ListItem disabled={disabled} key={i}>
            {render(item)}
          </ListItem>
        );
      })}
    </ul>
  );
}

List.defaultProps = {
  items: [],
};

List.propTypes = {
  isDisabled: PropTypes.func,
  items: PropTypes.array,
  render: PropTypes.func,
};
