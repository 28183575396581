import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UnansweredQuestionsList } from "./UnansweredQuestionsList/UnansweredQuestionsList";

import "./FormSubmitPageErrors.css";

export function FormSubmitPageErrors({ eventId, itemsWithoutValue }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-submit-page-errors__title">
        {t("form.submit.missed_something")}
      </div>
      <div className="form-submit-page-errors__go-back">
        {t("form.submit.go_back")}
      </div>
      <UnansweredQuestionsList eventId={eventId} items={itemsWithoutValue} />
    </>
  );
}

FormSubmitPageErrors.propTypes = {
  eventId: PropTypes.string.isRequired,
  itemsWithoutValue: PropTypes.array.isRequired,
};
