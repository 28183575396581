// https://developer.mozilla.org/en-US/docs/web/api/document/cookie


function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/; Secure; SameSite=Strict`;
}

function getCookie(name) {
    return document.cookie.split("; ")
        .find((row) => row.startsWith(`${name}=`))
        ?.split("=")[1] ?? null;
}

function eraseCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export { setCookie, getCookie, eraseCookie };
