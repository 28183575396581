import "./FileSelect.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as PlusIcon } from "content/icons/plus-circle.svg";

export function FileSelect({
  ariaDescribedBy,
  className,
  disabled,
  id,
  inputRef,
  label,
  onChange,
}) {
  return (
    <div
      className={classNames({
        "file-select": true,
        "file-select--disabled": disabled,
        [className]: !!className,
      })}
    >
      <input
        id={id}
        ref={inputRef}
        type="file"
        className="file-select__input"
        onChange={onChange}
        disabled={disabled}
        aria-label={label}
        aria-describedby={ariaDescribedBy}
      />
      <label className="file-select__label" htmlFor={id}>
        <PlusIcon /> {label && <span>{label}</span>}
      </label>
    </div>
  );
}

FileSelect.propTypes = {
  ariaDescribedBy: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
