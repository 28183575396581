import "./Thumbnail.css";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as FileIcon } from "content/icons/file.svg";

export function Thumbnail({ className, fileName, onClick }) {
  // Limit file extension to 4 chars to fit default file icon
  const extension = fileName.split(".").pop().substring(0, 4);

  return (
    <div
      className={classNames({
        thumbnail: true,
        [className]: !!className,
        "thumbnail--link": !!onClick,
      })}
      aria-label={fileName}
      onClick={onClick}
    >
      <FileIcon />
      {extension && <div className="thumbnail__extension">{extension}</div>}
    </div>
  );
}

Thumbnail.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
