import { HtmlText } from "components/Base/HtmlText/HtmlText";
import PropTypes from "prop-types";

export function FormItemLabel({ oid, label }) {
  return (
    <HtmlText className="form-item__label" element="label" htmlFor={oid} htmlString={label} />
  );
}

FormItemLabel.propTypes = {
  oid: PropTypes.string,
  label: PropTypes.string,
};
