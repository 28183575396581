import { createContext, useReducer } from "react";
import PropTypes from "prop-types";

export const actionTypes = {
    ADD_LOADING_ITEM: "ADD_LOADING_ITEM",
    REMOVE_LOADING_ITEM: "REMOVE_LOADING_ITEM",
};

const loadingReducer = (state, { type, key }) => {
    switch (type) {
        case actionTypes.ADD_LOADING_ITEM:
            return state.includes(key) ? state : [...state, key];
        case actionTypes.REMOVE_LOADING_ITEM:
            return state.filter((val) => val !== key);
        default:
            return state;
    }
};

export const LoadingContext = createContext();

export const LoadingContextProvider = ({ children }) => {
    const [loadingItems, dispatch] = useReducer(loadingReducer, []);

    const isLoading = loadingItems && loadingItems.length > 0;

    return (
        <LoadingContext.Provider value={{ isLoading, dispatch }}>
            {children}
        </LoadingContext.Provider>
    );
};

LoadingContextProvider.propTypes = {
    children: PropTypes.any,
};
