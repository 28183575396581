import React, { useEffect, useContext } from "react";
import userManager, { signinRedirectCallback } from "utils/oidcClientExtension";
import { appInsightsTelemetry } from "ApplicationInsightsService";
import { useHistory } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { i18n } from "localization/i18n";
import { setCookie, getCookie } from "helpers/cookies";
import { onPostChangePinMessage } from "utils/broadcastChannelUtils";
import { useAsyncError } from "hooks/useAsyncError";

function SigninOidc() {
  const history = useHistory();
  const throwError = useAsyncError();

  const { setAuthenticated, setUserMustChangePinCode } =
    useContext(UserContext);

  useEffect(() => {
    async function signinAsync() {
      let user = null;

      try {
        user = await signinRedirectCallback();
      } catch (e) {
        appInsightsTelemetry.trackException(e);

        // Clear local storage of entries that are not completed and older than 300s (configured in oidc-client)
        // This is to make sure that if the user has to re-login, there is no stale state that might cause issues.
        await userManager.clearStaleState();
      }

      if (user) {
        try {
          i18n.changeLanguage(user.profile.lang);
          setAuthenticated(true);
  
          setCookie(
            process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME,
            user.profile.must_change_pin
          );
          setUserMustChangePinCode(user.profile.must_change_pin);
  
          if (getCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME)) {
            onPostChangePinMessage();
          }
        } catch (e) {
          throwError(e);
        }
      }

      history.push("/");
    }

    signinAsync();
  }, [history, setAuthenticated, setUserMustChangePinCode, throwError]);

  return <></>;
}

export default SigninOidc;
