import PropTypes from "prop-types";
import "./ValidationError.css";

export const ValidationError = ({ id, message }) => {
  return (
    <div id={id} className="validation-error">
      {message}
    </div>
  );
};

ValidationError.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
};
