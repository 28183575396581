import { useState, useRef, useEffect } from "react";

const STATE = Object.freeze({
    HIDE: "HIDE",
    DELAY: "DELAY",
    DISPLAY: "DISPLAY",
});

const minDuration = 750;
const delay = 300;

export const useLoadingOverlayDelay = (isLoading) => {
    const [state, setState] = useState(STATE.HIDE);
    const loadingVisibleSince = useRef(null);
    const timer = useRef(null);

    useEffect(() => {
        if (isLoading && state === STATE.HIDE) {
            setState(STATE.DELAY);

            timer.current = setTimeout(() => {
                if (!isLoading) {
                    setState(STATE.HIDE);
                } else {
                    setState(STATE.DISPLAY);
                    loadingVisibleSince.current = Date.now();
                }
            }, delay);
        }

        if (!isLoading && state === STATE.DISPLAY) {
            const loadingDuration = Date.now() - loadingVisibleSince.current;
            const timeLeft = Math.max(minDuration - loadingDuration, 0);
            timer.current = setTimeout(() => {
                setState(STATE.HIDE);
            }, timeLeft);
        }

        if (!isLoading && state === STATE.DELAY) {
            clearTimeout(timer.current);
            setState(STATE.HIDE);
        }
    }, [isLoading, state]);

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    return state === "DISPLAY";
};
